import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import '@fontsource/unifont';
import './about.scss';
import { useTranslation } from 'react-i18next';

import badgeLogo from '../images/badge2.png';

const generateRandomChar = () => {
  const characters = '10';
  return characters.charAt(Math.floor(Math.random() * characters.length));
};

export default function About() {
  const { t } = useTranslation();
  const [animatedTexts, setAnimatedTexts] = useState({
    title: '',
    paragraphO: '',
    paragraphT: '',
  });

  useEffect(() => {
    const textsToAnimate = {
      title: t('about.title'),
      paragraphO: t('about.paragraphO'),
      paragraphT: t('about.paragraphT'),
    };

    (Object.keys(textsToAnimate) as (keyof typeof textsToAnimate)[]).forEach(
      (key) => {
        const finalText = textsToAnimate[key];

        let animatedText = Array.from(finalText)
          .map(() => generateRandomChar())
          .join('');

        setAnimatedTexts((prev) => ({
          ...prev,
          [key]: animatedText,
        }));

        const interval = setInterval(() => {
          animatedText = animatedText
            .split('')
            .map((char, index) => {
              return index < finalText.length ? generateRandomChar() : char;
            })
            .join('');

          setAnimatedTexts((prev) => ({
            ...prev,
            [key]: animatedText,
          }));
        }, 100);

        const timeout = setTimeout(() => {
          clearInterval(interval);
          setAnimatedTexts((prev) => ({
            ...prev,
            [key]: finalText,
          }));
        }, 300);

        return () => {
          clearInterval(interval);
          clearTimeout(timeout);
        };
      }
    );
  }, [t]);

  return (
    <Box className="aboutContainer">
      <Box className="titleContainer">
        <img className="badgeLogoAbout" src={badgeLogo} alt="badge logo"></img>
        <Typography className="aboutTitle" variant="h1" sx={{ mb: 2 }}>
          {animatedTexts.title}
        </Typography>
      </Box>
      <Box className="paragraphContainer">
        <Typography className="aboutParagraph">
          {animatedTexts.paragraphO}
        </Typography>
      </Box>
      <Box className="paragraphContainer">
        <Typography className="aboutParagraph">
          {animatedTexts.paragraphT}
        </Typography>
      </Box>
    </Box>
  );
}
